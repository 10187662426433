<ng-template #btnReply>
    <a
        *ngIf="message.replyStatusMessage.canReply && folder != 'out' && !isModeReply"
        class="col-auto text-end btn btn-link border"
        href="javascript:void(0)" (click)="showReplyForms()"><i
            class="fas fa-reply text-primary" aria-hidden="true"
            [attr.aria-label]="'messages.actionsMessage.reply.label' | translate"></i>{{'messages.actionsMessage.reply.label'
        | translate}}</a>

</ng-template>
<!--!previousMessage &&-->
<div id='detail' *ngIf="message !=undefined" data-testid="testIdDetail">
    <p class="me-sm-0 ms-sm-0 mt-0 mb-sm-0 w-100 d-block">
        <a href="javascript:void(0)" (click)="backResultMessage()"
            data-testid="returnLink" class="returnLink">{{"detail-of-message.return.".concat(folder)
            | translate }}</a>
    </p>
    <div
        class="{{isMobile ? 'd-block': 'd-flex align-items-center'}} {{isModeReply && !isMobile ? 'border-0' : 'border-bottom'}} flex-wrap">
        <h2
            class="ps-0 pt-0 pb-0  m-0 {{(  (allPartitions != null && allPartitions.length > 1) || message.replyStatusMessage.canReply) && !isMobile ? 'col' :'col-12'}}">
            <span class="visually-hidden">{{'detail-of-message.main' | translate
                }}</span>{{message.title}}
        </h2>
        <ng-container *ngTemplateOutlet="isMobile ? btnReply : null"></ng-container>
        <strong class="text-danger border border-2 border-danger rounded bg-danger px-2 py-1 me-2 " style="--bs-bg-opacity: .015;"
            *ngIf="message.statusMessageData.registeredMailStatus.isRegisteredMail()">{{'detail-of-message.registeredMail.'.concat(message.statusMessageData.registeredMailStatus.type).concat(".label") | translate }}</strong>
        <a
            *ngIf="isMobile && folder != 'out'  && !isModeReply"
            class="col-auto text-end btn btn-link border d-inline-block text-nowrap"
            href="javascript:void(0)"
            (click)="changeStateOfMessage()"><i
                class="fa  fa-eye-slash text-primary" aria-hidden="true"></i> {{'messages.actionsMessage.maskUnMaskMessage'.concat(message.statusMessageData.visible
            ? '.mask' : '.unmask').concat('.name') | translate }}</a>
        <ng-container *ngTemplateOutlet="!isMobile ? btnReply : null"></ng-container>
        <a *ngIf="allPartitions == null || allPartitions.length > 1"
            class="col-auto text-end btn btn-link border"
            href="javascript:void(0)"
            (click)="moveCurrentMessageToOtherPartition()"><i
                class="fas fa-share text-primary" aria-hidden="true"
                [attr.aria-label]="'messages.actionsMessage.move.modal.header.title.one' | translate"></i>{{'messages.actionsMessage.move.button'
            | translate}}</a>
    </div>

    <ng-container *ngIf="message != null">
        <app-forms-bidirectional *ngIf="isModeReply && folder!='out'"
            [message]="message" class="border w-100 mt-sm-3 mb-sm-4 d-block"></app-forms-bidirectional>
        <hr class="d-sm-none" *ngIf="isModeReply" />
    </ng-container>
    <app-detail [message]="message" [bodyContent]="bodyContent"
        [previousMessage]="false"
        [recipientPartitionName]="!appConstant.isNullOrUndefined(message.recipient.partition) ? (message.recipient.partition | partitionNamePipe) : ('pacman.partitionName.default' | translate)">

    </app-detail>
    <ng-container *ngIf="originalMessage !== undefined">
        <hr />
        <app-detail [message]="originalMessage"
            [bodyContent]="originalBodyContent"
            [recipientPartitionName]="!appConstant.isNullOrUndefined(originalMessage.recipient.partition) ? (originalMessage.recipient.partition | partitionNamePipe) : ('pacman.partitionName.default' | translate)"
            [previousMessage]="true" class="previousMessage"></app-detail>
    </ng-container>
</div>
