import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Sender } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { SenderOrganization } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { NGXLogger } from "ngx-logger";
import { Subscription } from 'rxjs';
import { ApplicationConstants } from '../../app.constants';
import { HandleExceptionService } from '../../error/handle-exception-service.service';
import { FormsHelper } from '../../helper/Forms-helper';
import { Attachment } from '../../interfaces/legacy-reply-to-message';
import { MessageToPublish } from '../../interfaces/message-to-publish';
import { Partition } from '../../pacman/interfaces/partition';
import { ActionsService, LoadingService, MailService } from '../../services';
import { StateFn } from "../../store/StateFn";
import { ActionsStore } from '../../store/actions-store';
import { DataForms, StatusForms, StoreShape } from '../../store/store-data-interface';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'app-manual-publication',
  templateUrl: './manual-publication.component.html',
  styles: []
})
export class ManualPublicationComponent implements OnInit, OnDestroy {

  private pathWs: string;
  public isMobile: boolean;
  private _userLanguage: string;

  //for componentHTMl
  public stateFnFe: StateFn;

  private subscriptions: Subscription[] = [];
  // private reader: FileReader = new FileReader();

  errorsForms: boolean;
  statusSendingForm: StatusForms;
  StatusFormsEnum: any;
  resultForm: DataForms;
  myPartitions: Partition[];

  isUploaded: boolean;
  private _manualPublicationForm: FormGroup;
  mimeTypeAuthorized: string[] = ApplicationConstants.mimeTypeAuthorized;
  private _attachments: Attachment[];


  public get attachments(): Attachment[] {
    return this._attachments;
  }


  public set attachments(value: Attachment[]) {
    this._attachments = value;
  }

  private _messageToPublish: MessageToPublish;

  constructor(private logger: NGXLogger, private _actionService: ActionsService, private _loadingService: LoadingService, private _stateFn: StateFn,
    private _actionsService: ActionsService, private _storeService: StoreService,
    private _configService: ConfigurationService, private cdRef: ChangeDetectorRef,
    private _mailService: MailService, private _handleException: HandleExceptionService,
    private sanitizer: DomSanitizer, private _actionsStore: ActionsStore) {
    this.pathWs = this._configService.getEnvironmentVariable('urlBackendEnterpriseRest');
    this.manualPublicationForm = new FormGroup({
      attachmentMessage: new FormControl<File | null>(null),
      senderPartitionInput: new FormControl<string | null>('', { validators: [Validators.required], updateOn: 'change' }),
      destinationEnterpriseInput: new FormControl<string | null>('', { validators: [FormsHelper.companyNumberValidator(), Validators.required], updateOn: 'change' },),
      titleOfMessageInput: new FormControl<string | null>('', { validators: [FormsHelper.requiredAndTrimValueValidator(), Validators.maxLength(this.maxLengthTitle)], updateOn: 'change' }),
      messageTextarea: new FormControl<string | null>('', { validators: FormsHelper.requiredAndTrimValueValidator(), updateOn: 'change' })
    });
    this.statusSendingForm = null;
    this.errorsForms = true;
    this.StatusFormsEnum = StatusForms;
    this.stateFnFe = this._stateFn;
    this.resultForm = null;
    //if (stateFn.getAllReferencesData() == null) this._actionsService.initAllReferenceData();
  }

  ngOnInit() {
    if (this._stateFn.isAccessManualPublication()) {
      this.manualPublicationForm.statusChanges.subscribe(status => {

        if (status === 'INVALID') {
          if (this.manualPublicationForm.controls['messageTextarea'].valid && this.manualPublicationForm.controls['destinationEnterpriseInput'].valid && this.manualPublicationForm.controls['titleOfMessageInput'].valid) {
            if (this.manualPublicationForm.controls['attachmentMessage'].invalid && this.manualPublicationForm.controls['attachmentMessage'].getError('attachmentMessageError') != null) {
              this.errorsForms = false;
            } else if (this.manualPublicationForm.controls['attachmentMessage'].invalid) {
              this.errorsForms = true;
            } else {
              this.errorsForms = false
            }
          } else {
            this.errorsForms = true;
          }
        } else {
          this.errorsForms = false
        }

      })
      this.subscriptions.push(this._storeService.store.subscribe((resultStore: StoreShape) => {
        this._messageToPublish = {
          isBodyMainContent: true,
          bodyContent: null,
          recipient: {
            eboxType: "enterprise", enterpriseNumber: null
          },
          sender: {
            eboxType: "enterprise", enterpriseNumber: this._stateFn.getUser().cbeNumber, partition: null
          },
          subject: {},
          isReplyAuthorized: false,
          senderApplicationId: 'APPLICATION_MANUAL_PUB',
          messageTypeId: 'MESSAGE_TYPE_MANUAL_PUB'
        };
        this.statusSendingForm = resultStore.userActions.dataForms.statusForms;
        this.resultForm = this._stateFn.getDataForms();
        this._userLanguage = resultStore.userSession.localeParameter;
        this.isMobile = resultStore.isMobile;
        if (this.myPartitions != null && this.myPartitions.length > 0 && this._manualPublicationForm.get('senderPartitionInput').value == '') {
          this._manualPublicationForm.get('senderPartitionInput').setValue(this.myPartitions[0].id, { onlySelf: true });
        }
        this._loadingService.stop('manual-publication init');

      }));
    } else {
      this._handleException.forbiddenAccess();
      throw new Error('Invalid scope manual publication');
    }
    this.myPartitions = this.putGeneralFirst(this._stateFn.getAllPartitions());
    this.isUploaded = true;
  }

  private putGeneralFirst(partitions: Partition[]): Partition[] {
    partitions.sort((a, b) => {
      if (b.id === 'GENERAL') {
        return 1;
      }
      return a.id.localeCompare(b.id);
    })
    return partitions;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // generate javascript:void(0)
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  removeAttachment(index: number) {

    // logger.log(this._messageToReply.attachments);
    this._attachments.splice(index, 1);

  }

  getAttachments() {
    return this._attachments;
  }

  backResultMessage() {
    this._actionService.backSearchAndMessageFiltersAndPage();
  }

  onMailFormDataSubmit() {
    this._messageToPublish.bodyContent = {};
    this._messageToPublish.bodyContent[this._userLanguage] = this.manualPublicationForm.get('messageTextarea').value;
    this.logger.info('result', JSON.stringify(this._messageToPublish));
    this._messageToPublish.subject[this._userLanguage] = this.manualPublicationForm.get("titleOfMessageInput").value;
    this._messageToPublish.recipient.enterpriseNumber = (this.manualPublicationForm.get("destinationEnterpriseInput").value as string).replace(FormsHelper.regexpCompanyNumber, "");
    this._messageToPublish.sender.partition = this.manualPublicationForm.get("senderPartitionInput").value;
    this._loadingService.start({ serviceRunStart: "manual publication send", delayedStart: false });
    this._mailService.sendMailPoc(this.pathWs + ApplicationConstants.publishMessagePath, FormsHelper.toFormData(this._messageToPublish, this._attachments, null, this._stateFn)).then(
      resp => {
        const recipient: Sender = { value: this._messageToPublish.recipient.enterpriseNumber, type: this._messageToPublish.recipient.eboxType, companyName: null, companyId: null, applicationId: null, applicationName: null, logoHref: null };
        this._actionsService.getSenderOrganization(this._messageToPublish.recipient.enterpriseNumber, "socsec_v1").then((organization: SenderOrganization) => {
          if (organization != null) {
            recipient.companyName = organization.name[this._userLanguage];
          }
        }).finally(() => {
          this._actionsStore.updateStatusManualForms(StatusForms.success, recipient);
          this._loadingService.stop("Success send Mail");
          this.manualPublicationForm.setErrors(null);
        })
      })
      .catch(error => {

        this._loadingService.stop("Error send Mail");
        const handlingsError = FormsHelper.handleSendErrorForms(error, this.statusSendingForm);
        if (handlingsError.status == StatusForms.failed) {
          this._actionsStore.updateStatusManualForms(StatusForms.failed, null);

        } else {
          this._actionsStore.updateStatusManualForms(StatusForms.error, null);
        }

        if (handlingsError.errorOBj != null) {
          this.manualPublicationForm.setErrors({ 'manualPublicationFormHttpError': handlingsError.errorOBj });

          this.cdRef.markForCheck();

        }

      });
  }

  onKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById('customFileLang').click();
    }
  }

  onFileChange(event) {
    this.isUploaded = false;
    const inputFile: HTMLInputElement = (event.target || event.srcElement || event.currentTarget) as HTMLInputElement;

    if (inputFile.files && inputFile.files.length > 0) {
      this._loadingService.start({ serviceRunStart: "uploadFile", delayedStart: true });

      const attachmentsCount = this._attachments ? this._attachments.length : 0;
      let errors: [{ typeOfError: string, args?: [string | number] }] = null;
      if (attachmentsCount + inputFile.files.length > this.maxNumberFile) {
        this.manualPublicationForm.controls.attachmentMessage.setErrors({ 'attachmentMessageError': FormsHelper.fillErrors(errors, 'maxFile', null) });

        //this.manualPublicationForm.get('attachmentMessage').setErrors({ 'attachmentMessageError': FormsHelper.fillErrors(errors, 'maxFile', null) });

        inputFile.value = '';

        return;
      }
      const fileList: FileList = inputFile.files;
      //let counter = 0;
      const promiseForUploadFiles: Promise<any>[] = Array.from(fileList).map((file: File) => new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
          const fileName = file.name;
          const extension: string = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
          let errorTemp: [{ typeOfError: string, args?: [string | number] }] = null;
          // check extension
          if (this.mimeTypeAuthorized.indexOf(extension.toLowerCase()) == -1) {
            errorTemp = FormsHelper.fillErrors(errorTemp, 'mimetype', fileName);
            this.logger.error('mimeType is not correct', extension.toLowerCase())
          } else if (file.size == 0) {
            errorTemp = FormsHelper.fillErrors(errorTemp, 'emptyFile', fileName);
          } else if (fileName.length > this.maxLengthFilename) {
            errorTemp = FormsHelper.fillErrors(errorTemp, 'maxlengthFilename', this.maxLengthFilename);

          }
          resolve([{ content: file, fileName: file.name, mediaType: file.type, fileSize: file.size }, errorTemp]);
        };
        reader.readAsDataURL(file);
      })
      );

      Promise.all(promiseForUploadFiles).then(
        fileTmp => {

          fileTmp.forEach(fileResult => {
            const attachmentTotalSize = this.getCurrentTotalSize();
            const attachment: Attachment = fileResult[0];
            let errorAttachment: [{ typeOfError: string, args?: [string | number] }] = fileResult[1];
            if ((attachment.fileSize / (1024 * 1024 * this.maxSizeFile) > 1 || ((attachment.fileSize + attachmentTotalSize) / (1024 * 1024 * this.maxSizeFile) > 1))) {
              errorAttachment = FormsHelper.fillErrors(errorAttachment, 'maxSize', attachment.fileName);
            }

            if (errorAttachment == null) {
              if (this._attachments === undefined) {
                this._attachments = [];
              }
              this._attachments.push(attachment);
            } else {
              errorAttachment.forEach(error => {
                errors = FormsHelper.fillErrors(errors, error.typeOfError, error.args[0]);
              })
            }
          });
        }
      ).finally(
        () => {
          this.isUploaded = true;
          if (errors != null) {
            this.manualPublicationForm.controls.attachmentMessage.setErrors({ 'attachmentMessageError': errors });
            this._loadingService.stop('Error load file');
          } else {
            this._loadingService.stop("finish load file stopLoading");
          }
        }
      )
    }

    //this.isUploaded = true;

  }

  public hasErrors(): boolean {
    // logger.log('validation messageTextarea %s, validationPhoneNumer %s', this.manualPublicationForm.controls['messageTextarea'].status, this.manualPublicationForm.controls['destinationEnterpriseInput'].status)
    if (this.manualPublicationForm.controls['messageTextarea'].valid && this.manualPublicationForm.controls['destinationEnterpriseInput'].valid) {
      if (this.manualPublicationForm.controls['attachmentMessage'].invalid && this.manualPublicationForm.getError('attachmentMessageError')[0] == 'maxFile') {
        return false;
      } else if (this.manualPublicationForm.controls['attachmentMessage'].invalid) {
        return true;
      }
      return false
    }

    return true;

  }

  private getCurrentTotalSize(): number {
    let count = 0;
    if (this._attachments != undefined) {
      this._attachments.forEach(attach => {
        count += attach.fileSize;
      });
    }
    return count;
  }

  public isFileOnlySizeError(): boolean {
    let leng = null;
    if (Object.keys(this.manualPublicationForm.errors).length == 1) {
      const errorFile = this.manualPublicationForm.getError('attachmentMessageError');
      leng = (errorFile[0] == 'maxFile') ? true : false;
      if (this.manualPublicationForm.get('messageTextarea').status == 'VALID') leng = false;

    }
    return leng;
  }

  public get manualPublicationForm(): FormGroup {
    return this._manualPublicationForm;
  }

  public set manualPublicationForm(value: FormGroup) {
    this._manualPublicationForm = value;
  }

  public get language(): string {
    return this._stateFn.getLanguage();
  }

  get f() { return this.manualPublicationForm.controls; }

  get maxSizeFile() { return ApplicationConstants.maxSizeFile; }

  get maxNumberFile() { return ApplicationConstants.maxNumberFile; }

  get maxLengthFilename() { return ApplicationConstants.maxLengthFilename; }

  get maxLengthTitle() { return ApplicationConstants.maxLengthTitle; }

}
