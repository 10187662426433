import { LocalizedString } from '@angular/compiler';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import {
  MessageData,
  StatusMessageActionData
} from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { MessageAction, MessageActionStatusToPatch, RegisteredMailType } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api';
import { Subscription } from "rxjs";
import { ApplicationConstants } from '../../app.constants';
import { BusinessDataHelper } from "../../helper/BusinessDataHelper";
import { DateHelper } from "../../helper/DateHelper";
import { MessageActionType } from '../../interfaces/enum/messageActionType';
import { EnterpriseUser } from "../../models/enterprise-user";
import { TranslatedStringPipe } from '../../pipes';
import { ActionsService, LoadingService, ResponsiveService } from '../../services';
import { StateFn } from "../../store/StateFn";
import { ActionsStore } from '../../store/actions-store';
import { StoreService } from '../../store/store.service';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styles: []
})
export class DetailComponent implements OnInit, OnDestroy {

  private EN_DATE_PATTERN = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$");

  private _enterpriseUser: EnterpriseUser;
  public isMobile: boolean;
  private _language: string;
  private _bodyContent: string;
  isModeReply: boolean;
  private _folder: string;
  public isExecuted: boolean;
  public statusMessageWithRequiredAction: StatusMessageActionData;
  public techDataCollapsed = true;
  public businessDataCollapsed = false;
  public displayReadDate = false;

  public get folder(): string {
    return this._folder;
  }

  @Input()
  previousMessage: boolean;

  @Input()
  recipientPartitionName: string;

  private _message: MessageData;

  public messageActionsValuesSize: number;

  public msgActionReplyWithExternalForm: MessageAction;
  public modalConfirmLeaveModal: NgbModalRef;

  // eslint-disable-next-line
  @ViewChild("confirmLeaveEboxTemplate") confirmLeaveEboxTemplate: TemplateRef<any>;
  urlToLeave: string;


  constructor(
    private _router: Router, private _route: ActivatedRoute, private responsiveService: ResponsiveService,
    private _storeService: StoreService, private stateFn: StateFn, public appConstant: ApplicationConstants,
    private _actionsService: ActionsService, private _actionsStore: ActionsStore, private _translateService: TranslateService, private _dateHelper: DateHelper, private modalService: NgbModal, private _loadingService: LoadingService, private _translatedPipe: TranslatedStringPipe) {
    this._language = this.stateFn.getLanguage();
    this._bodyContent = "";
    this.isModeReply = false;
    this._folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
  }

  private subscriptions: Subscription[] = []

  ngOnInit() {
    this.subscriptions.push(this.responsiveService.registerToChange(this));
    this.subscriptions.push(this._storeService.store.subscribe(store => {
      this._folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
      this._enterpriseUser = store.userSession.user;
      this.isModeReply = store.userActions.dataForms.isModeReply;
      if (this.message != null && this._language !== store.userSession.localeParameter) {
        this._language = store.userSession.localeParameter;

      }
    }));

    this._route.queryParams.subscribe(params => {
      if (params.idMessage !== undefined && params.idProviderRegistry) {

      } else {
        this._router.navigate(['/']);
      }
    });

    if (this.folder == 'out' && this.previousMessage) {
      const companyName: string = this.stateFn.getUser().enterpriseName;
      if (companyName.length > 0) {
        this.message.recipient.companyName = companyName;
      }
    }

    this.statusMessageWithRequiredAction = this.messageWithRequiredAction();
    this.isExecuted = this.statusMessageWithRequiredAction?.executed;

    for (const key in this._message.businessData) {
      if (BusinessDataHelper.isTechnicalData(key)) {
        this.technicalData.set(key, this._message.businessData[key]);
      } else {
        this.businessData.set(key, this._message.businessData[key]);
      }
    }
    this.messageActionsValuesSize = 0;
    if (this.message.statusMessageActions) {
      this.message.statusMessageActions.filter(stateMsgActions => stateMsgActions.messageActionId === MessageActionType.MESSAGE_WITH_ACTION_REQUIRED).forEach(statusMsgActions => {
        this.messageActionsValuesSize += 1;
        if (statusMsgActions.executionDueDate) {
          this.messageActionsValuesSize += 1;
        }


      });
    }




    this.businessDataCollapsed = !(this.businessData.size > 0 || this.messageActionsValuesSize > 0);
    this.displayReadDate = !this.appConstant.isNullOrUndefined(this.message.statusMessageData.readDate) && this.message.statusMessageData.readStatus && this.folder == 'out';

    if (this.message.statusMessageData.registeredMailStatus.type === RegisteredMailType.QualifiedRegisteredMailNotification && this.message.statusMessageActions && this.message.statusMessageActions.length > 0) {
      const messageActionsP: Promise<MessageAction>[] = this.message.statusMessageActions.map(statusMsgAction => {
        return this._actionsService.getMessageActionById(statusMsgAction.messageActionId, this.message.registryId);
      })

      Promise.all(messageActionsP).then(allMessageActions => {
        this.msgActionReplyWithExternalForm = allMessageActions.find(msgAction => msgAction.type === 'replyWithExternalForm');
      })

    }
  }
  confirmLeaveEbox(urlToRecommend: LocalizedString) {
    const ngbOptions: NgbModalOptions = {
      modalDialogClass: 'confirmDialog',
      keyboard: true,
      backdrop: 'static',
      centered: true,
      windowClass: 'confirmBoxAction',
      size: 'md',

    };
    this.urlToLeave = this._translatedPipe.transform(urlToRecommend);
    ngbOptions.keyboard = false;
    this.modalConfirmLeaveModal = this.modalService.open(this.confirmLeaveEboxTemplate, ngbOptions);
    this.modalConfirmLeaveModal.shown.subscribe(() => this._loadingService.stop("start open Optin Flow"));

    this.modalConfirmLeaveModal.result.then((statusFormModal) => {

    }, (rejected => {
    })).finally(() => {
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  public get message(): MessageData {
    return this._message;
  }

  @Input()
  public set message(value: MessageData) {
    this._message = value;
  }

  public get bodyContent(): string {
    return this._bodyContent;
  }

  @Input()
  public set bodyContent(value: string) {
    this._bodyContent = value;
  }

  public isLoggedUser(ssin: String) {
    return this.stateFn.isOpenIdActive() ? (ssin === this._storeService.store.getValue().userSession.user.ssin) : false;
  }

  updateMessageActionStatus(event: Event) {
    const input: HTMLInputElement = (event.target || event.currentTarget) as HTMLInputElement;
    if (!this.appConstant.isNullOrUndefined(this.statusMessageWithRequiredAction)) {
      const messageActionToPatch: MessageActionStatusToPatch = {
        isExecuted: input.checked
      };
      this._actionsService.updateMessageActionStatus(messageActionToPatch, this._message)
        .then(() => {
          const messageActionRequired = this.stateFn.getTotalItemsMessageAction();
          this._actionsStore.updateNbMessagesActionRequired(this.isExecuted ? messageActionRequired + 1 : messageActionRequired - 1);
          this.isExecuted = !this.isExecuted
        });
    }
  }

  public messageWithRequiredAction(): StatusMessageActionData {
    if (!this.appConstant.isNullOrUndefined(this.message.statusMessageActions)) {
      return this.message.statusMessageActions.find(messageAction => messageAction.messageActionId === MessageActionType.MESSAGE_WITH_ACTION_REQUIRED);
    }
  }

  public get enterpriseUser(): EnterpriseUser {
    return this._enterpriseUser;
  }


  public set enterpriseUser(value: EnterpriseUser) {
    this._enterpriseUser = value;
  }

  public getFormattedValue(value: string): string {
    if (value === "true" || value === "false") {
      return this._translateService.instant('detail-of-message.businessData.'.concat(value));
    } else if (this.EN_DATE_PATTERN.test(value)) {
      return this._dateHelper.getFormatedDate(new Date(value), DateHelper.DEFAULT_FORMAT_FULL_YEAR);
    } else {
      return value;
    }
  }

  public get technicalData(): Map<string, Object> {
    const techData = new Map<string, Object>();
    for (const key in this._message.businessData) {
      if (BusinessDataHelper.isTechnicalData(key)) {
        techData.set(key, this._message.businessData[key]);
      }
    }
    return techData;
  }

  public get businessData(): Map<string, Object> {
    const businessData = new Map<string, Object>();
    for (const key in this._message.businessData) {
      if (!BusinessDataHelper.isTechnicalData(key) && !BusinessDataHelper.isExceptionKeyBusinessData(key)) {
        businessData.set(key, this._message.businessData[key]);
      }
    }
    return businessData;
  }
}
