<!--The content below is only a placeholder and can be replaced.-->
<div (window:resize)="onResize()">

    <div *ngIf="isStateUseDp" class="w-100 ps-4 pe-4 pt-2 pb-2"
        id="dpLocalEnnabledBanner">
        <h2><span class="d-inline-block">{{'registry.banner.info' | translate}}</span>
            <button class="btn btn-link d-inline-block float-end text-white" type="button" data-bs-toggle="collapse" data-bs-target="#listMethodToolsRegistry" aria-expanded="false"
                aria-controls="listMethodToolsRegistry">{{'registry.banner.help' | translate }}</button>
        </h2>

        <div class="card-body collapse me-sm-0 ms-sm-0 pe-sm-0 ps-sm-0"
            id="listMethodToolsRegistry" [innerHTML]="getToolsRegistryInfo()">

        </div>
    </div>

    <smals-ngx-version-control></smals-ngx-version-control>
    <!-- head !-->

    <app-header *ngIf="showHeader" [isUserLoaded]='isUserLoaded'
        [errorLogin]="errorLogin">
    </app-header>
    <ng-container *ngIf="authenticationState >= 5">
        <app-communication *ngIf='isUserLoaded && !errorLogin && configRegistriesLoaded != null && configRegistriesLoaded.length != 0' [isMobile]="this.isMobile" [typeAlertVisible]="'info,warning,error'"
            class="d-block"> </app-communication>
    </ng-container>
    <!-- end head !-->
    <div class="{{isUserLoaded ? 'container-fluid' :''}}">
        <div class="{{isUserLoaded ? 'row'.concat(this.isMobile ? ' mx-0' :'') :''}}">
            <app-leftnav id="tmpLeftnav"
                class="d-block col-sm-1 col-md-1 col-lg-2 pe-0"
                *ngIf="isUserLoaded" [errorLogin]="errorLogin"
                [isConsultMessage]='isConsultMessage' [isMobile]="isMobile"></app-leftnav>
            <app-main
                class="{{isUserLoaded ? 'col-12 col-sm-11 col-md-11 col-lg-10' : (errorLogin ? 'ps-0':(isLoginPage ? 'w-100' :'w-100 d-block'))}}  {{!isLoginPage && !isInfoPage ?'':'no-after'}}"
                [isUserLoaded]='isUserLoaded' [isEboxLoaded]="isEboxLoaded"
                [isConsultMessage]='isConsultMessage'
                [configRegistriesLoaded]="configRegistriesLoaded"
                [isInfoPage]="isInfoPage" [errorLogin]="errorLogin"></app-main>
        </div>
    </div>
    <app-footer *ngIf="isUserLoaded">
    </app-footer>
    <app-loading></app-loading>
</div>