<nav class="pull-right {{positionPagination}}" role="navigation" [attr.aria-label]="'search.paginator.name' | translate">
    <ul class="pagination {{currentPage > 1 && currentPage < maxPage ? 'justify-content-between':'justify-content-end'}}">
        <li class="page-item previous" *ngIf="currentPage > 1">
            <a class="btn btn-primary" href="javascript:void(0)" [attr.aria-label]="'search.paginator.previousPage' | translate" (click)="prevPageClick()" data-type-search="-1" data-original-title='top'>
                <span>{{'search.paginator.previousPage' | translate }}</span>
            </a>
        </li>
        <li class="page-item next" *ngIf="currentPage < maxPage">
            <a class="btn btn-primary" href="javascript:void(0)" [attr.aria-label]="'search.paginator.nextPage' | translate" (click)="nextPageClick()" data-type-search="1">
                <span>{{'search.paginator.nextPage' | translate }}</span>
            </a>
        </li>
    </ul>

</nav>
